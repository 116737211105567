import { useState } from "react"

import fin from "./level_50.jpg";

const steps = {
    start: 'start',
    look: 'look',
    look1: 'look1',
    
    room: 'room',
    room1: 'room1',
    bath: 'bath',
    bath1: 'bath1',
    theRoom: 'theRoom',
    theRoom1: 'theRoom1',
    kitchen: 'kitchen',
    kitchen1: 'kitchen1',
    
    lookRoom: 'lookRoom',
    lookRoom1: 'lookRoom1',
    lookBath: 'lookBath',
    lookBath1: 'lookBath1',
    lookTheRoom: 'lookTheRoom',
    lookTheRoom1: 'lookTheRoom1',
    lookKitchen: 'lookKitchen',
    lookKitchen1: 'lookKitchen1',
    
    tv: 'tv',
    poop: 'poop',
    bike: 'bike',
    cookie: 'cookie',
};

const desc = {
    start: `
            Ты дома. 
            <div>
            Ты хочешь повесить свой <code>телевизор</code> на стену и тебе для этого нужен <code>строительный уровень</code>.
            <br/>Всё остальное у тебя уже есть.
        </div>
    `,
    look: `В квартире есть <code>комната</code>, <code>кухня</code>, <code>балконозаменитель</code> (кладовка), <code>ванная</code>.`,
    look1: `Где же он может быть?!`,
    room: `В <code>комнате</code> есть <code>телевизор</code>.`,
    room1: `В <code>комнате</code> его точно не должно быть! Что бы ему тут делать?!`,
    lookRoom: `Увы, в <code>комнате</code> похоже нет <code>уровня</code>.`,
    lookRoom1: `Да нет его в <code>комнате</code>!`,
    bath: `Ты в <code>ванной</code>. Здесь есть <code>туалет</code>.`,
    bath1: `В <code>ванной</code> его в принципе не было! Что за бред?!`,
    lookBath: `Увы, в <code>ванной</code> похоже нет <code>уровня</code>.`,
    lookBath1: `Ты на что вообще надеялся?`,
    theRoom: `В <code>кладовке</code> всякое <code>барахло</code>. И <code>велосипед</code>. 
    <br/>И <code>уровень</code> наверняка где-то здесь!`,
    theRoom1: `Ну <code>кладовка</code> - самое место для <code>уровня</code>!`,
    lookTheRoom: `Увы, в <code>кладовке</code> похоже нет <code>уровня</code>.`,
    lookTheRoom1: `Ура! Ты нашёл <code>уровень</code> в <code>кладовке</code>!
    <br/>🎉
    <br/>Конец.
    <br/>
    <br/>Fin.
    <br/>
    <br/>
    <br/>Всё.
    `,
    kitchen: `На <code>кухне</code> куча <code>барахла</code>. И <code>печеньки</code>.`,
    kitchen1: `Может на <code>кухне</code> всё-таки где-то в <code>барахле</code>?`,
    lookKitchen: `Увы, на <code>кухне</code> похоже нет <code>уровня</code>.`,
    lookKitchen1: `Да бл😡, и на <code>кухне</code> нет нигде!`,
    poop: `💩 Теперь на поиски <code>уровня</code>!`,
    bike: `🚲 Балдёж.`,
    cookie: `🍪 Каеф.`,
    tv: `Телевизор отключен. Сначала найди <code>уровень</code>.`,
}

const actions = {
    start: [
        {
            id: steps.look,
            title: 'Осмотреться'
        }
    ],
    look: [],
    bath: [
        {
            id: steps.lookBath,
            title: 'Поискать уровень'
        },
        {
            id: steps.poop,
            title: 'Покакать'
        }
    ],
    bath1: [
        {
            id: steps.lookBath1,
            title: 'Поискать в стиралке'
        },
    ],
    room: [
        {
            id: steps.lookRoom,
            title: 'Поискать уровень'
        },
        {
            id: steps.tv,
            title: 'Посмотреть телевизор'
        }
    ],
    room1: [
        {
            id: steps.lookRoom1,
            title: 'Поискать по углам'
        },
    ],
    tv: [
        {
            id: steps.lookRoom,
            title: 'Поискать уровень'
        },
    ],
    lookBath: [
        {
            id: steps.poop,
            title: 'Покакать'
        }
    ],
    theRoom: [
        {
            id: steps.lookTheRoom,
            title: 'Порыться в барахле'
        },
        {
            id: steps.bike,
            title: 'Осмотреть велосипед'
        }
    ],
    theRoom1: [
        {
            id: steps.lookTheRoom1,
            title: 'А это что?!'
        },
    ],
    bike: [
        {
            id: steps.lookTheRoom,
            title: 'Порыться в барахле'
        },
    ],
    lookTheRoom: [
        {
            id: steps.bike,
            title: 'Осмотреть велосипед'
        }
    ],
    kitchen: [
        {
            id: steps.lookKitchen,
            title: 'Порыться в барахле'
        },
        {
            id: steps.cookie,
            title: 'COOKIE! 🍪'
        }
    ],
    kitchen1: [
        {
            id: steps.lookKitchen1,
            title: 'Ну в барахле же!'
        },
    ],
    lookKitchen: [
    ],
    cookie: [
        {
            id: steps.lookKitchen,
            title: 'Порыться в барахле'
        },
    ],
    poop: [
        {
            id: steps.lookBath,
            title: 'Поискать уровень'
        },
    ]
}

const inc = (value) => value + 1;

export const LevelOne = () => {
    const [step, setStep] = useState('start');
    const [oneMore, setOneMore] = useState(false);
    const [roomSearched, setRoomSearched] = useState(0);
    const [bathSearched, setBathSearched] = useState(0);
    const [theRoomSearched, setTheRoomSearched] = useState(0);
    const [kitchenSearched, setKitchenSearched] = useState(0);
    const [pooped, setPooped] = useState(0);

    const handleClick = (actionId) => {
        return () => {

            switch (actionId) {
                case steps.lookRoom:
                case steps.lookRoom1:
                    setRoomSearched(inc);
                    break;
                case steps.lookBath:
                case steps.lookBath1:
                    setBathSearched(inc);
                    break;
                case steps.lookTheRoom:
                case steps.lookTheRoom1:
                    setTheRoomSearched(inc);
                    break;
                case steps.lookKitchen:
                case steps.lookKitchen1:
                    setKitchenSearched(inc);
                    break;
                case steps.poop:
                    setPooped(inc);
                    break;
                case steps.look1:
                    setOneMore(true);
                    break;
                default:
                    break;
            }

            setStep(actionId);
        }
    }
    
    const hideLookButtons = oneMore || [
        steps.start, 
        steps.room, 
        steps.bath,  
        steps.theRoom, 
        steps.kitchen,
    ].includes(step);

    const hideOneMoreButtons = step === steps.lookTheRoom1 || [
        steps.room1, 
        steps.bath1,  
        steps.theRoom1, 
        steps.kitchen1
    ].includes(step);


    const searchedEverywhere = roomSearched && bathSearched && theRoomSearched && kitchenSearched;

    return <div>
        {step === steps.lookTheRoom1 ? 
        <img  className="fin" src={fin} alt="" />
        : <h1>Уровень 1</h1>}
       
        <p dangerouslySetInnerHTML={{__html: desc[step]}}>
        </p>
        
        {searchedEverywhere && !oneMore ? 
        <p dangerouslySetInnerHTML={{__html:        
             `Ты поискал везде, но <code>уровень</code> так и не нашёл?
             <br/> Странные дела! `
    }}>
        </p>
    : null}
        <p>
            {actions[step]?.map(({id, title}) => {
                if (step === steps.lookBath && pooped) {
                    return null;
                }

                if (step === steps.bath && pooped && id === steps.poop) {
                    return null;
                }

                if (step === steps.bike && theRoomSearched) {
                    return null;
                }

                if (step === steps.cookie && kitchenSearched) {
                    return null;
                }

                if (step === steps.tv && roomSearched) {
                    return null;
                }

                return <button key={id} onClick={handleClick(id)}>{title}</button>;
            })}
            {hideLookButtons ? null : <>
            {(roomSearched || step === steps.tv) ? null : <button onClick={handleClick(steps.room)}>Осмотреть комнату</button>}
            {(bathSearched || step === steps.poop) ? null : <button onClick={handleClick(steps.bath)}>Осмотреть ванную</button>}
            {(theRoomSearched || step === steps.bike) ? null : <button onClick={handleClick(steps.theRoom)}>Осмотреть кладовку</button>}
            {(kitchenSearched || step === steps.cookie) ? null : <button onClick={handleClick(steps.kitchen)}>Осмотреть кухню</button>}
            </>}
            {searchedEverywhere && !oneMore ? <button onClick={handleClick(steps.look1)}>Поискать ещё разок</button> : null}
            {!oneMore || hideOneMoreButtons ? null : <>
            {(roomSearched === 2) ? null : <button onClick={handleClick(steps.room1)}>Осмотреть комнату</button>}
            {(bathSearched === 2) ? null : <button onClick={handleClick(steps.bath1)}>Осмотреть ванную</button>}
            {(theRoomSearched === 2) ? null : <button onClick={handleClick(steps.theRoom1)}>Осмотреть кладовку</button>}
            {(kitchenSearched === 2) ? null : <button onClick={handleClick(steps.kitchen1)}>Осмотреть кухню</button>}
            </>}
        </p>
    </div>
}