import logo from './level.png';
import './App.css';
import { useState } from 'react';
import { LevelOne } from './LevelOne';

function App() {
  const [isStarted, setIsStarted] = useState(false);
  const handleStartClick = () => {
    setIsStarted(true);
  }

  return (
    <div className="App">
      {isStarted ? <LevelOne/>:<header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Найди свой <code>уровень</code>.
        <div>The Game</div>

        </p>
        <button
          className="link"
          onClick={handleStartClick}
        >
          Начать
        </button>
      </header>
      }
    </div>
  );
}

export default App;
